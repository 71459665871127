import { startOfDay } from 'date-fns/esm';
import { clamp } from 'lodash-es';
import { firstBy } from 'thenby';
import { ComegoTime } from 'timeghost-api';
import { hoursToFormat } from './utils';

export interface WorkingHourViolation {
  type: 'maxHours' | 'break' | 'rest';
  message: string;
  value: string | number;
  errorActAsWarning?: boolean;
  args?: Record<string, any>;
  rule?: any;
  date: string;
}
const typeToTranslateKey = {
  maxHours: 'errors.times.comego.dailyMaxHours-alert',
  break: 'errors.times.comego.breakRuleNotice-alert',
  rest: 'errors.times.comego.breakBetweenWorkingDays-alert',
};
export function evaluateWorkingHours(
  times: ComegoTime[],
  workingHourRules: {
    dailyMaxHours?: number;
    breakBetweenWorkingDays?: number;
    breaks?: Array<{ workingHours: number; minutesDue: number }>;
  },
  date = new Date(),
  options: { prevDayItem: ComegoTime; nextDayItem: ComegoTime } = {} as typeof options,
): WorkingHourViolation[] {
  const violations: WorkingHourViolation[] = [];
  const { nextDayItem, prevDayItem } = options;

  // Create working copy of times with temporary violation tracking
  const workingTimes = times
    .map((time) => ({
      ...time,
      _tempViolations: new Set<string>(),
    }))
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

  let runningWorkMs = 0;
  let runningPauseMs = 0;

  // Check rest period with previous day
  if (workingHourRules.breakBetweenWorkingDays && prevDayItem && workingTimes.length > 0) {
    const firstWork = workingTimes.find((x) => x.type === 'work');
    if (firstWork) {
      const currentStart = new Date(firstWork.start);
      const prevEnd = new Date(prevDayItem.end);
      const restMs = currentStart.getTime() - prevEnd.getTime();
      const requiredRestMs = workingHourRules.breakBetweenWorkingDays * 3600 * 1000;

      if (restMs < requiredRestMs) {
        violations.push({
          type: 'rest',
          message: typeToTranslateKey.rest,
          value: hoursToFormat(workingHourRules.breakBetweenWorkingDays),
          date: startOfDay(prevEnd).toISOString(),
          args: {
            breakBetweenWorkingDays: hoursToFormat(workingHourRules.breakBetweenWorkingDays),
          },
        });
        firstWork._tempViolations.add('rest_prev');
      }
    }
  }

  for (const time of workingTimes) {
    const end = time.end ? new Date(time.end) : new Date();
    const start = new Date(time.start);
    const diff = clamp(end.getTime() - start.getTime(), 0, Number.MAX_SAFE_INTEGER);

    if (time.type === 'work') {
      runningWorkMs += diff;

      // Check daily maximum hours
      if (workingHourRules.dailyMaxHours) {
        const maxHoursMs = workingHourRules.dailyMaxHours * 3600 * 1000;
        if (runningWorkMs > maxHoursMs && !time._tempViolations.has('maxHours')) {
          violations.push({
            type: 'maxHours',
            message: typeToTranslateKey.maxHours,
            value: hoursToFormat(workingHourRules.dailyMaxHours),
            date: startOfDay(start).toISOString(),
            args: {
              dailyMaxHours: hoursToFormat(workingHourRules.dailyMaxHours),
            },
          });
          time._tempViolations.add('maxHours');
        }
      }

      // Check break rules
      if (workingHourRules.breaks?.length) {
        const sortedBreakRules = workingHourRules.breaks.toSorted(firstBy((x) => x.workingHours, 'asc'));

        for (const breakRule of sortedBreakRules) {
          const breakKey = `break_${breakRule.workingHours}_${breakRule.minutesDue}`;
          const requiredWorkMs = breakRule.workingHours * 3600 * 1000;
          const requiredPauseMs = breakRule.minutesDue * 3600 * 1000;

          if (
            runningWorkMs > requiredWorkMs &&
            runningPauseMs < requiredPauseMs &&
            !time._tempViolations.has(breakKey)
          ) {
            violations.push({
              type: 'break',
              message: typeToTranslateKey.break,
              value: runningWorkMs,
              rule: breakRule,
              date: startOfDay(start).toISOString(),
              args: {
                workingHours: hoursToFormat(breakRule.workingHours),
                minutesDue: hoursToFormat(breakRule.minutesDue),
              },
            });
            time._tempViolations.add(breakKey);
          }
        }
      }
    } else if (time.type === 'pause') {
      runningPauseMs += diff;
    }
  }

  // Check rest period with next day
  if (workingHourRules.breakBetweenWorkingDays && nextDayItem && workingTimes.length > 0) {
    const lastWork = workingTimes.toReversed().find((x) => x.type === 'work');
    if (lastWork) {
      const currentEnd = new Date(lastWork.end || new Date());
      const nextStart = new Date(nextDayItem.start);
      const restMs = nextStart.getTime() - currentEnd.getTime();
      const requiredRestMs = workingHourRules.breakBetweenWorkingDays * 3600 * 1000;

      if (restMs < requiredRestMs && !lastWork._tempViolations.has('rest_next')) {
        violations.push({
          type: 'rest',
          message: typeToTranslateKey.rest,
          value: hoursToFormat(workingHourRules.breakBetweenWorkingDays),
          date: startOfDay(currentEnd).toISOString(),
          args: {
            breakBetweenWorkingDays: hoursToFormat(workingHourRules.breakBetweenWorkingDays),
          },
        });
        lastWork._tempViolations.add('rest_next');
      }
    }
  }
  return violations;
  // return violations.map((d: any) => {
  //   if (!d.meta?.errors) d.meta = Object.assign({}, d.meta || {}, { errors: [] });
  //   return d;
  // });
}
